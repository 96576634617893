import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: ApiService) {}

  getUrlPath(url: string): string {
    return `${environment.media}/${url}`;
  }

  getUrlFileName(url: string): string {
    return url.split('/').pop() || 'file';
  }

  getFileFromUrl(url: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const filePath = this.getUrlPath(url);
      const fileName = url.split('/').pop() || 'file';
      this.apiService.downloadFile(filePath).subscribe({
        next: (blob) => {
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
